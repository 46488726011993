import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Icon, Box } from '@popmenu/common-ui';

import { createEvent } from '~/utils/eventable';
import { withIntl } from '../../utils/withIntl';
import { withStyles } from '../../utils/withStyles';
import styles from './styles';

import { setVipData } from '../ModalActions';

import BasicForm, { TextFieldGroup, SubmitGroup } from '../../admin/shared/forms/BasicForm';
import EngagementSVG from '../../assets/svg/engagement.svg';
import { notBlankValidator } from '../../utils/forms';
import ReviewEngagementSVG from '../../assets/svg/review_engagement.svg';

const EmailAuthenticationForm = (props) => {
  const vipData = useSelector(state => state.modals.vipData);
  const { isPopPath, isReviewPath } = vipData;

  const header = (
    isPopPath && !isReviewPath ? (
      props.t('consumer.vip_pop_path.title')
    ) : (
      props.t('consumer.vip_review_path.title')
    )
  );

  const icon = (
    isPopPath && !isReviewPath ? (
      EngagementSVG
    ) : (
      ReviewEngagementSVG
    )
  );

  return (
    <div className={props.classes.container}>
      <Icon icon={icon} className={props.classes.accessCodeIcon} />
      <Box align="center" color="primary.main" className={props.classes.header} id="become-vip-title">
        {header}
      </Box>
      <Box id="become-vip-description">
        <Box align="center" color="primary.main" className={props.classes.headerSecondary}>
          <FormattedMessage id="consumer.vip_pop_path.subtitle" defaultMessage="It only takes 5 seconds" />
        </Box>
        <Box align="center" className={props.classes.content}>
          <FormattedMessage id="consumer.vip_pop_path.body" defaultMessage="The more items you engage with the closer you get to loyalty rewards, personalized recommendations and exclusive offers." />
        </Box>
      </Box>
      <BasicForm
        onSubmit={({ email }) => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: isPopPath ? 'pop_event_continue_button' : 'review_event_continue_button',
          });
          props.setVipData({ userEmail: email });
        }}
        data-cy="email_authentication_form"
      >
        {({ values }) => (
          <React.Fragment>
            <TextFieldGroup
              color="primary"
              data-cy={'email_field'}
              field="email"
              fullWidth
              label={props.t('consumer.vip.email')}
              placeholder={props.t('consumer.vip.enter_your_email')}
              type="email"
              variant="outlined"
              validate={notBlankValidator}
            />
            <SubmitGroup
              block
              data-cy="email_authentication_submit"
              disabled={!values.email}
              title={(<FormattedMessage id="forms.continue" defaultMessage="Continue" />)}
              fullWidth
              size="large"
            />
          </React.Fragment>
        )}
      </BasicForm>
    </div>
  );
};

EmailAuthenticationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  setVipData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  connect(() => ({}), {
    setVipData,
  }),
  withStyles(styles),
  withIntl,
)(EmailAuthenticationForm);
