export default theme => ({
  accessCodeIcon: {
    height: '200px',
    margin: theme.spacing(-4),
    width: '200px',
  },
  accessCodeSubmitButton: {
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.5),
    marginTop: theme.spacing(-0.5),
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    marginTop: theme.spacing(1),
  },
  phonePreview: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-1),
  },
  sendCodeButton: {
    color: theme.palette.common.black,
  },
  subheader: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(4),
  },
  tryDifferentButton: {
    color: 'black',
  },
});
