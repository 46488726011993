export default theme => ({
  accessCodeIcon: {
    height: '200px',
    margin: theme.spacing(-4),
    width: '200px',
  },
  accessCodeSubmitButton: {
    marginTop: theme.spacing(-1),
  },
  avatar: {
    height: '100px',
    width: '100px',
  },
  avatarContainer: {
    margin: theme.spacing(2),
  },
  bottomContainer: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(2, -6, 0, -4),
    padding: theme.spacing(2, 10, 2, 10),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  buttonLink: {
    color: theme.palette.info.dark,
    fontSize: theme.typography.caption.fontSize,
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    padding: theme.spacing(0, 4),
    textAlign: 'center',
  },
  content: {
    marginBottom: theme.spacing(2.5),
  },
  dateFieldTitle: {
    color: 'rgba(0, 0, 0, 0.65)',
    textAlign: 'start',
  },
  dateFieldTitleWrapper: {
    padding: `${theme.spacing(1, 1.7)} !important`,
  },
  dividerText: {
    backgroundColor: theme.palette.common.white,
    color: theme.consumer?.general.mutedText.color,
    padding: theme.spacing(0, 1),
  },
  dividerWrapper: {
    borderBottom: `1px solid ${theme.consumer?.general.mutedText.color}`,
    lineHeight: '0.1em',
    margin: theme.spacing(4, 0),
    textAlign: 'center',
    width: '100%',
  },
  drawer: {
    overflowX: 'hidden',
    width: '100%',
  },
  email: {
    color: theme.palette.common.black,
  },
  fieldsetWrapper: {
    margin: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
    width: '100%',
  },
  footerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  footerText: {
    color: '#595959 !important',
    fontSize: theme.typography.caption.fontSize,
  },
  form: {
    margin: theme.spacing(2.5, 0, 8),
  },
  formContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    padding: theme.spacing(0, 4),
  },
  formMessage: {
    marginBottom: theme.spacing(2),
  },
  formTitle: {
    color: theme.palette.grey[700],
    textAlign: 'center',
  },
  formWrapper: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    marginTop: theme.spacing(4),
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    marginTop: theme.spacing(1),
  },
  header2: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
  },
  headerColor: {
    color: theme.palette.primary.main,
  },
  headerSecondary: {
    marginBottom: theme.spacing(4),
  },
  headerTertiary: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    fontSize: theme.typography.h1.fontSize,
    height: '100px',
    marginTop: theme.spacing(6),
    width: '100px',
  },
  infoPageButton: {
    marginTop: theme.spacing(3),
    textTransform: 'capitalize',
  },
  logoColumn: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.spacing(2, 2, 0, 0),
      display: 'none',
      marginBottom: 0,
      marginLeft: 0,
      marginTop: 0,
    },
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '0.5rem 0 0 0.5rem',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(-0.5),
  },
  passwordCaption: {
    display: 'block',
    marginTop: theme.spacing(-2),
  },
  phoneSubmitButton: {
    marginTop: theme.spacing(-1),
  },
  pmSignUpV2Btn: {
    marginBottom: theme.spacing(-1),
  },
  popmenuLink: {
    color: theme.palette.info.dark,
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(4, 0, -4, 0),
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  popmenuLinkContainer: {
    margin: theme.spacing(4),
  },
  popmenuLogo: {
    height: theme.spacing(2.25),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.25),
    width: theme.spacing(10),
  },
  restaurantLogo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '90%',
    objectFit: 'contain',
  },
  signInLink: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    width: '100%',
  },
  signInLinkButton: {
    borderRadius: 0,
    boxShadow: theme.shadows[2],
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  signUpIcon: {
    height: theme.spacing(18.75),
    margin: theme.spacing(-2.5),
    width: theme.spacing(18.75),
  },
  subheader: {
    color: theme.consumer?.general.mutedText.color,
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(4),
  },
  subheaderEmail: {
    fontSize: theme.typography.caption.fontSize,
  },
  submitButton: {
    width: '100%',
  },
  suleDarken: {
    filter: 'brightness(0) invert(0)',
  },
  suleLighten: {
    filter: 'brightness(0) invert(1)',
  },
  termsButton: {
    color: '#595959 !important',
    fontSize: '0.75em',
    lineHeight: 'inherit',
    marginTop: theme.spacing(2),
    minHeight: 0,
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
  },
  verifiedPageButton: {
    fontSize: theme.typography.body1.fontSize,
    height: theme.spacing(5.25),
    marginTop: theme.spacing(2),
    width: '80%',
  },
  verifiedPageContainer: {
    textAlign: 'center',
  },
  verifiedPageIcon: {
    height: theme.spacing(18.75),
    width: theme.spacing(18.75),
  },
  verifiedPageSubtitle: {
    color: theme.consumer?.general.mutedText.color,
  },
  verifiedPageTitle: {
    color: theme.palette.primary.main,
  },
  verifiedSkipForNowButton: {
    margin: theme.spacing(0),
    textTransform: 'none',
    width: '100%',
  } }
);
