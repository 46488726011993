import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Button } from '@popmenu/admin-ui';
import { Icon, Box, Typography } from '@popmenu/common-ui';

import { createEvent } from '~/utils/eventable';
import { withStyles } from '../../utils/withStyles';
import styles from './styles';

import CheckEmailSVG from '../../assets/svg/check_your_email.svg';

const MagicLinkSentPage = ({ classes, closeVipModal, userEmail }) => (
  <React.Fragment>
    <Box align="center">
      <Icon icon={CheckEmailSVG} className={classes.accessCodeIcon} />
    </Box>
    <Box align="center">
      <Typography className={classes.header} id="become-vip-title" aria-live="polite" role="status">
        <FormattedMessage id="sessions.magic_link_header" defaultMessage="Check your email" />
      </Typography>
    </Box>
    <Box align="center">
      <Typography className={classes.subheader} id="become-vip-description" aria-live="polite" role="status">
        <React.Fragment>
          <FormattedMessage id="sessions.magic_link_subheader" defaultMessage="We just sent a magic link to your email" />
          <Box className={classes.email}>
            {userEmail}
          </Box>
          <Box align="center">
            <FormattedMessage id="sessions.magic_link_subheader_info" defaultMessage="The magic link will sign you in." />
          </Box>
        </React.Fragment>
      </Typography>
    </Box>
    <Box align="center" style={{ margin: '0 auto', width: '80%' }}>
      <Button
        className={classes.accessCodeSubmitButton}
        variant="primary"
        fullWidth
        onClick={() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'v2_magic_link_ok',
          });
          closeVipModal();
        }}
        size="large"
      >
        {<FormattedMessage id="sessions.sign_in" defaultMessage="Ok" />}
      </Button>
    </Box>
  </React.Fragment>
);

MagicLinkSentPage.propTypes = {
  classes: PropTypes.object.isRequired,
  closeVipModal: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
)(MagicLinkSentPage);
