import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useMutation } from '~/lazy_apollo/client';
import { getApolloClient } from '~/lazy_apollo';
import { compose } from '@shakacode/recompose';
import { Grid, Avatar, Button, Icon, Typography } from '@popmenu/common-ui';

import { useSelector } from 'react-redux';
import { Mail, Phone } from '@popmenu/web-icons';
import { createEvent } from '~/utils/eventable';
import { usePopmenuConfig } from '../../utils/withPopmenuConfig';
import { withIntl } from '../../utils/withIntl';
import { withStyles } from '../../utils/withStyles';
import { withSnackbar } from '../../utils/withSnackbar';
import { useSnackbar } from '../../utils/withSnackbar';
import sendUserAutoLoginEmailMutation from '../../libs/gql/mutations/users/sendUserAutoLoginEmailMutation.gql';
import signInUserMutation from '../../libs/gql/mutations/users/signInUserMutation.gql';
import sendUserSmsAccessCodeMutation from '../../libs/gql/mutations/users/sendUserSmsAccessCodeMutation.gql';
import currentSessionQuery from '../../libs/gql/queries/users/currentSessionQuery.gql';
import signInStyles from '../VipV2Modal/styles';

import OfferTag from '../../assets/svg/popmenu_guest_profile_offer_tag.svg';
import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import { AH, AHLevelProvider } from '../../consumer/shared/AccessibleHeading';
import { useFeatureFlags } from '../../utils/featureFlagsContext';

export const submitSignInUser = (variables, signInUser, props) => {
  signInUser({
    variables: {
      ...variables,
      favoriteLocationId: props.favoriteLocationId,
      restaurantId: props.restaurantId,
    },
  });
};

const SignInV2Form = (props) => {
  const { isFeatureActive } = useFeatureFlags();
  const isLoyaltyEnabled = isFeatureActive('isLoyaltyEnabled');

  const [sendUserAutoLoginEmail] = useMutation(sendUserAutoLoginEmailMutation);
  const userName = props.userData.displayName ? `, ${props.userData.displayName}` : '';
  const offersCount = props.userData.orderingOfferCodesCount;
  const [sendUserSmsAccessCode] = useMutation(sendUserSmsAccessCodeMutation);
  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);
  const { showSnackbarError } = useSnackbar();
  const popmenuConfig = usePopmenuConfig();

  const [signInUser] = useMutation(signInUserMutation, {
    onCompleted: (res) => {
      if (!props.userData.isTextValidated && isLoyaltyEnabled) {
        props.requireValidation();
      }
      getApolloClient().then((client) => {
        client.writeQuery({
          data: {
            currentSession: res.signInUser,
            popmenuConfig,
          },
          query: currentSessionQuery,
        });
        // Sometimes in production the user gets stuck on the screen after login, refetching the user manually to avoid this error
        // https://app.shortcut.com/popmenu/story/78134/sign-in-form-not-updating-when-user-signs-in-via-safari-from-mobile-device
        props.refetchUser();
        console.log('[POPMENU] Sign-in completed');
      });
    },
    onError: err => showSnackbarError(err),
  });

  useEffect(() => {
    if (isOnOloPage && props.userData?.orderingOfferCodesCount > 0) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | follower | offer | sign_in_modal',
        eventType: 'follower_profile_sign_in_offer_modal',
      });
    } else if (isOnOloPage) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | follower | no_offer | sign_in_modal',
        eventType: 'follower_profile_sign_in_no_offer_modal',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendAccessToken = () => {
    // send the magic link to the given email address
    sendUserAutoLoginEmail({
      variables: {
        email: props.userEmail,
        restaurantId: props.restaurantId,
        source: props.followerSource,
      },
    }).then(() => props.useMagicLink()).catch(() => props.showSnackbarError('Something went wrong with your request'));
  };

  const onSendAccessCode = () => {
    // send access code text to user phone number
    sendUserSmsAccessCode({
      variables: {
        restaurantId: props.restaurantId,
        userEmail: props.userEmail,
      },
    }).then(() => {
      props.useAccessCode();
    }).catch((err) => {
      console.warn(err);
    });
  };

  const { avatarUrl } = props.userData;
  const renderLinks = () => (
    <div className={props.classes.signInLinks}>
      {(
        <Button
          className={props.classes.signInLinkButton}
          color="primary"
          onClick={() => {
            createEvent({
              eventableType: 'BecomeVipV2',
              eventType: 'v2_verify_magic_link_button',
            });
            onSendAccessToken();
          }}
          textTransform="none"
          size="large"
          variant="outlined"
        >
          <Icon icon={Mail} className={props.classes.buttonIcon} />
          <FormattedMessage id="sessions.sign_in_magic_link" defaultMessage="Email me a Magic Link" />
        </Button>
      )}
      {props.userData.lastFourDigitsPhoneNumber && (
        <Button
          className={props.classes.signInLinkButton}
          color="primary"
          onClick={() => {
            createEvent({
              eventableType: 'BecomeVipV2',
              eventType: 'v2_verify_access_code_button',
            });
            onSendAccessCode();
          }}
          textTransform="none"
          size="large"
          variant="outlined"
        >
          <Icon icon={Phone} />
          <FormattedMessage id="sessions.sign_in_access_code" defaultMessage="Text me an Access Code" />
        </Button>
      )}
    </div>
  );
  const renderForm = () => (
    <BasicForm
      aria-labelledby="sign-in-title"
      defaultValues={{
        email: props.userEmail,
        password: null,
      }}
      id="sign-in-v2-form"
      labelPosition="top"
      onSubmit={variables => submitSignInUser(variables, signInUser, props)}
    >
      {({ values }) => {
        const submitDisabled = !values.password || !values.email;
        return (
          <React.Fragment>
            <TextFieldGroup
              id="pm-signin-email-input"
              field="email"
              title={props.t('users.email')}
              type="email"
            />
            {props.userData.usePassword && (
              <React.Fragment>
                <TextFieldGroup
                  id="pm-signin-pw-input"
                  field="password"
                  title={props.t('users.password')}
                  style={props.style}
                  type="password"
                />
                <SubmitGroup
                  block
                  ButtonProps={{ lowercase: true }}
                  color="primary"
                  disabled={submitDisabled}
                  icon={false}
                  justify="center"
                  size="lg"
                  title="Sign In"
                />
                {props.userData.enableLink && (
                  <Typography align="center" className={props.classes.dividerWrapper}>
                    <span className={props.classes.dividerText}>
                      <FormattedMessage id="sessions.sign_in.or" defaultMessage="or" />
                    </span>
                  </Typography>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }}
    </BasicForm>
  );

  return (
    <Grid container>
      {/* New guest profile flow. Below will only render when attempting email check via ProfileV2 olo page and user having offers */}
      {isOnOloPage && props.userData?.orderingOfferCodesCount > 0 ? (
        <React.Fragment>
          <Grid item xs={12} align="center">
            <OfferTag width={60} height="100%" />
          </Grid>
          <Grid item xs={12}>
            <AH typography align="center" className={props.classes.headerTertiary} variant="h3" id="become-vip-title" aria-live="polite" role="status">
              <FormattedMessage
                id="sessions.with_offers.sign_in"
                defaultMessage="Hi {displayName},{br}you have {offersCount} available {offer}!"
                values={{ br: <br />, displayName: props.userData.displayName, offer: offersCount === 1 ? 'offer' : 'offers', offersCount }}
              />
            </AH>
          </Grid>
          <AHLevelProvider>
            <Grid item xs={12} align="center">
              <AH
                typography
                align="center"
                className={props.classes.subheader}
                variant="h5"
                id="become-vip-description"
                aria-live="polite"
                role="status"
              >
                <FormattedMessage
                  id="sessions.with_offers.sign_in_v2_subtitle"
                  defaultMessage="Sign in to explore your new VIP profile and apply {offer}"
                  values={{ offer: offersCount === 1 ? 'this offer to your order' : 'one of them to your order' }}
                />
              </AH>
            </Grid>
          </AHLevelProvider>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid item xs={12} align="center" className={props.classes.avatarContainer}>
            <Avatar
              alt={userName || 'avatar'}
              className={props.classes.avatar}
              src={avatarUrl}
            />
          </Grid>
          <Grid item xs={12}>
            <AH typography align="center" className={props.classes.header} variant="h3" id="become-vip-title" aria-live="polite" role="status">
              <FormattedMessage
                id="sessions.sign_in"
                defaultMessage={`Welcome Back${userName}!`}
              />
            </AH>
          </Grid>
          <AHLevelProvider>
            <Grid item xs={12} align="center">
              <AH
                typography
                align="center"
                className={props.classes.subheader}
                variant="h5"
                id="become-vip-description"
                aria-live="polite"
                role="status"
              >
                <FormattedMessage
                  id="sessions.sign_in_v2_subtitle"
                  defaultMessage="Please verify your account"
                />
              </AH>
            </Grid>
          </AHLevelProvider>
        </React.Fragment>
      )}
      <AHLevelProvider>
        <Grid item xs={12} className={props.classes.formContainer}>
          {renderForm()}
        </Grid>
        <Grid item xs={12} className={props.classes.formContainer}>
          {props.userData.enableLink && renderLinks()}
        </Grid>
      </AHLevelProvider>
    </Grid>
  );
};

SignInV2Form.defaultProps = {
  favoriteLocationId: null,
  restaurantId: null,
  style: null,
};

SignInV2Form.propTypes = {
  favoriteLocationId: PropTypes.number,
  refetchUser: PropTypes.func.isRequired,
  restaurantId: PropTypes.number,
  restaurantName: PropTypes.string.isRequired,
  style: PropTypes.object,
  t: PropTypes.func.isRequired,
  useAccessCode: PropTypes.func.isRequired,
  useMagicLink: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default compose(
  withStyles(signInStyles),
  withSnackbar,
  withIntl,
)(SignInV2Form);
