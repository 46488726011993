export const updateAriaAttributes = (dropdownType, isDropdownOpen) => {
  const hiddenInputs = document.querySelectorAll('input[id^="react-select"][type="text"]');

  hiddenInputs.forEach((input) => {
    if (input && !input.hasAttribute('role')) {
      input.setAttribute('role', 'combobox');
    }

    if (dropdownType === 'Month') {
      input.setAttribute('aria-expanded', isDropdownOpen.toString());
    } else if (dropdownType === 'Day') {
      input.setAttribute('aria-expanded', isDropdownOpen.toString());
    } else {
      input.setAttribute('aria-expanded', 'false');
    }
  });

  const listBoxes = document.querySelectorAll('[role*="menuitem"]');
  listBoxes.forEach((list) => {
    if (list.hasAttribute('role')) {
      list.setAttribute('role', 'listbox');
    }
  });

  const options = document.querySelectorAll('[data-cy*="option"]');
  options.forEach((option) => {
    if (!option.hasAttribute('role')) {
      option.setAttribute('role', 'option');
    }
  });
};
