import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from '@popmenu/admin-ui';
import { Typography, Box, Icon } from '@popmenu/common-ui';

import { useMutation } from '~/lazy_apollo/client';
import { getApolloClient } from '~/lazy_apollo';
import { createEvent } from '~/utils/eventable';
import signInUserByAccessCodeMutation from '../../libs/gql/mutations/users/signInUserByAccessCodeMutation.gql';
import sendUserSmsAccessCodeMutation from '../../libs/gql/mutations/users/sendUserSmsAccessCodeMutation.gql';
import currentSessionQuery from '../../libs/gql/queries/users/currentSessionQuery.gql';
import { withStyles } from '../../utils/withStyles';
import styles from '../VipV2Modal/styles';

import AccessCodeSVG from '../../assets/svg/access_code.svg';
import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import { ACCESS_ACTION_LIMIT } from './utils';
import { usePopmenuConfig } from '../../utils/withPopmenuConfig';

const AccessCodeSignInForm = ({ classes, userData, userEmail, restaurantId, refetchUser, source }) => {
  const [sendUserSmsAccessCode] = useMutation(sendUserSmsAccessCodeMutation);

  // limit page calls of access code action, limited by ACCESS_ACTION_LIMIT
  const [accessCodeClicks, setAccessCodeClicks] = useState(0);
  const popmenuConfig = usePopmenuConfig();

  const onReSendAccessCode = () => {
    if (accessCodeClicks < ACCESS_ACTION_LIMIT) {
      // add click count to action limiter until limit reached
      setAccessCodeClicks(state => state + 1);
      // resend access code text to user phone number
      sendUserSmsAccessCode({
        variables: {
          restaurantId,
          userEmail,
        },
      }).catch((err) => {
        console.warn(err);
      });
    }
  };

  return (
    <React.Fragment>
      <Box align="center">
        <Icon icon={AccessCodeSVG} className={classes.accessCodeIcon} />
      </Box>
      <Box align="center">
        <Typography className={classes.header} id="become-vip-title" aria-live="polite" role="status">
          <FormattedMessage id="sessions.access_code_header" defaultMessage="Enter Access Code" />
        </Typography>
      </Box>
      <Box align="center">
        <Typography className={classes.subheader} id="become-vip-description" aria-live="polite" role="status">
          <FormattedMessage id="sessions.access_code_subheader" defaultMessage="We just sent an access code to your phone number" />
        </Typography>
      </Box>
      <Box align="center">
        <Typography>
          <FormattedMessage
            id="sessions.access_code_last_four"
            defaultMessage="***-***-{lastFour}"
            values={{ lastFour: userData.lastFourDigitsPhoneNumber }}
          />
        </Typography>
      </Box>
      <Box className={classes.formWrapper}>
        <BasicForm
          mutate={{
            mutation: signInUserByAccessCodeMutation,
            onCompleted: (res) => {
              getApolloClient().then((client) => {
                client.writeQuery({
                  data: {
                    currentSession: res.signInUserByAccessCode,
                    popmenuConfig,
                  },
                  query: currentSessionQuery,
                });
                refetchUser();
                console.log('[POPMENU] Access Code Sign-in completed');
              });
            },
            toVariables: variables => ({
              accessCode: variables.accessCode,
              source,
            }),
          }}
          onSubmit={() => {
            createEvent({
              eventableType: 'BecomeVipV2',
              eventType: 'v2_access_code_signin',
            });
          }}
        >
          {({ values }) => (
            <React.Fragment>
              <TextFieldGroup
                data-testid="access-code-validation-input"
                field="accessCode"
                autoComplete="one-time-code"
                title={<FormattedMessage id="sessions.access_code" defaultMessage="Access Code" />}
              />
              <SubmitGroup
                block
                ButtonProps={{ lowercase: true }}
                className={classes.accessCodeSubmitButton}
                color="primary"
                disabled={!values.accessCode}
                icon={false}
                justify="center"
                size="lg"
                title={<FormattedMessage id="sessions.sign_in" defaultMessage="Sign In" />}
              />
            </React.Fragment>
          )}
        </BasicForm>
      </Box>
      <Box align="center">
        <Button
          className={classes.buttonLink}
          variant="text"
          onClick={() => {
            createEvent({
              eventableType: 'BecomeVipV2',
              eventType: 'v2_send_code_again',
            });
            onReSendAccessCode();
          }}
          disabled={accessCodeClicks >= ACCESS_ACTION_LIMIT}
        >
          <FormattedMessage id="sessions.access_code_resend_button" defaultMessage="Send code again" />
        </Button>
      </Box>
    </React.Fragment>
  );
};

AccessCodeSignInForm.defaultProps = {
  refetchUser: () => {},
  restaurantId: null,
};

AccessCodeSignInForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  refetchUser: PropTypes.func,
  restaurantId: PropTypes.number,
  userData: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default withStyles(styles)(AccessCodeSignInForm);
