import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from '@popmenu/admin-ui';
import { Icon, Typography, Box } from '@popmenu/common-ui';

import { createEvent } from '~/utils/eventable';
import { classNames, withStyles } from '../../utils/withStyles';
import signInStyles from './styles';
import ConfettiSVG from '../../assets/svg/confetti.svg';

const SignUpSuccessPage = ({ classes, closeModal, firstName, goToProfileModal, restaurant }) => {
  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);
  // did the user submit their email through the become a vip follow prompt (FollowPromptV2.jsx)
  const didSubmitEmailWithFollowPrompt = useSelector(state => state.modals.didSubmitEmailWithFollowPrompt);

  useEffect(() => {
    if (isOnOloPage && restaurant.offerMessageCampaignCount > 0) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | not_follower | offer | verified_modal',
        eventType: 'not_follower_profile_sign_in_verified_offer_modal',
      });
    } else if (isOnOloPage) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | not_follower | no_offer | verified_modal',
        eventType: 'not_follower_profile_sign_in_verified_no_offer_modal',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showOffersMessage = (isOnOloPage || (didSubmitEmailWithFollowPrompt && restaurant.activeBecomeVipMarketingOffer)) && restaurant.offerMessageCampaignCount > 0;
  return (
    <Box marginX={1} className={classes.verifiedPageContainer}>
      <Icon icon={ConfettiSVG} className={classNames(classes.icon, 'pm-vip-with-confetti-icon')} />
      <Typography className={classes.header} id="become-vip-title" aria-live="polite" role="status">
        <FormattedMessage
          id="sessions.sign_in_v2_verified_subtitle"
          defaultMessage="Welcome{firstName}!"
          values={{
            firstName: firstName ? ` ${firstName}` : '',
          }}
        />
      </Typography>
      <Box id="become-vip-description" aria-live="polite" role="status">
        <Box marginY={1}>
          <Typography variant="body1" className={classes.subheader}>
            <FormattedMessage
              id="sessions.sign_in_v2_verified_subtitle"
              defaultMessage="You’ve successfully signed up and are now a VIP of {restaurant}!"
              values={{ restaurant: restaurant.name }}
            />
          </Typography>
        </Box>
        <Box marginTop={3} marginBottom={4}>
          <Typography variant="body1" className={classes.subheader}>
            <FormattedMessage id="sessions.sign_in_v2_verified_subtitle" defaultMessage="Enjoy exclusive VIP benefits like loyalty rewards, personal recommendations and offers." />
          </Typography>
        </Box>
      </Box>
      <Button
        color="primary"
        className={classes.verifiedPageButton}
        onClick={() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'sign_up_success_page_profile_button',
          });

          if (isOnOloPage) {
            if (showOffersMessage) {
              createEvent({
                eventableType: 'GuestProfile',
                eventLabel: 'offer_check | not_follower | offer | view_offers_button | view_offers',
                eventType: 'not_follower_profile_sign_in_view_offers_click',
              });
            } else {
              createEvent({
                eventableType: 'GuestProfile',
                eventLabel: 'offer_check | not_follower | no_offer | explore_profile_button | explore_profile',
                eventType: 'not_follower_profile_sign_in_explore_click',
              });
            }
          }

          if (showOffersMessage) {
            goToProfileModal('offers');
          } else {
            goToProfileModal('my_profile');
          }
        }}
        size="medium"
      >
        <FormattedMessage
          id="profile.profile_v2_view_profile_button"
          defaultMessage="{cta}"
          values={{ cta: (isOnOloPage || (didSubmitEmailWithFollowPrompt && restaurant.activeBecomeVipMarketingOffer)) ? 'View Offers' : 'Explore Profile' }}
        />
      </Button>
      <Button
        className={classes.verifiedPageButton}
        color="primary"
        onClick={() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'sign_up_success_page_done_button',
          });
          closeModal();
        }}
        variant="outlined"
        size="medium"
      >
        <FormattedMessage
          id="sessions.sign_in_v2_verified_button"
          defaultMessage="{cta}"
          values={{ cta: isOnOloPage ? 'Back to Order' : 'Done' }}
        />
      </Button>
    </Box>
  );
};

SignUpSuccessPage.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  goToProfileModal: PropTypes.func.isRequired,
};

export default withStyles(signInStyles)(SignUpSuccessPage);
