import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@popmenu/admin-ui';
import { Typography, Box, Icon } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core';
import { useMutation } from '~/lazy_apollo/client';

import { getApolloClient } from '~/lazy_apollo';
import { formatPhone } from '../../../utils/strings';
import { ACCESS_ACTION_LIMIT } from '../utils';
import signInUserByAccessCodeMutation from '../../../libs/gql/mutations/users/signInUserByAccessCodeMutation.gql';
import sendUserSmsAccessCodeMutation from '../../../libs/gql/mutations/users/sendUserSmsAccessCodeMutation.gql';
import destroySessionMutation from '../../../libs/gql/mutations/users/destroySessionMutation.gql';
import currentSessionQuery from '../../../libs/gql/queries/users/currentSessionQuery.gql';
import styles from './styles';
import AccessCodeSVG from '../../../assets/svg/access_code.svg';
import BasicForm, { SubmitGroup, TextFieldGroup } from '../../../admin/shared/forms/BasicForm';
import { useSnackbar } from '../../../utils/withSnackbar';
import { usePopmenuConfig } from '../../../utils/withPopmenuConfig';

const useStyles = makeStyles(styles);

const AccessCodeValidationForm = ({ isLoggedIn, lastFour, newUserPhone, setUseDifferentNumber, setUseValidationAccessCode, restaurantId, userEmail }) => {
  const classes = useStyles();

  const { showSnackbar, showSnackbarError } = useSnackbar();
  const popmenuConfig = usePopmenuConfig();

  const [accessCodeClicks, setAccessCodeClicks] = useState(0);
  const [sendUserSmsAccessCode] = useMutation(sendUserSmsAccessCodeMutation);
  const onReSendAccessCode = () => {
    if (accessCodeClicks < ACCESS_ACTION_LIMIT) {
      // add click count to action limiter until limit reached
      setAccessCodeClicks(state => state + 1);
      // resend access code text to user phone number
      sendUserSmsAccessCode({
        onCompleted: () => {
          showSnackbar('New Code Sent!');
        },
        variables: {
          restaurantId,
          userEmail,
        },
      }).catch((err) => {
        console.warn(err);
      });
    }
  };

  const [destroySession] = useMutation(destroySessionMutation);

  useEffect(() => {
    if (isLoggedIn) {
      destroySession().then(({ data }) => getApolloClient()
        .then(client => client.writeQuery({
          data: {
            currentSession: data.destroySession,
            popmenuConfig,
          },
          query: currentSessionQuery,
        }))).catch((err) => {
        showSnackbarError(err);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Box align="center">
        <Icon icon={AccessCodeSVG} className={classes.accessCodeIcon} />
      </Box>
      <Box align="center">
        <Typography className={classes.header} id="become-vip-title" aria-live="polite" role="status">
          <FormattedMessage id="sessions.access_code_header" defaultMessage="Enter Access Code" />
        </Typography>
      </Box>
      <Box align="center">
        <Typography className={classes.subheader} id="become-vip-description" aria-live="polite" role="status">
          <FormattedMessage id="sessions.access_code_subheader" defaultMessage="We just sent an access code to your phone number" />
        </Typography>
      </Box>
      <Box align="center">
        { /* TODO: fix in sc-75168 */ }
        { /* eslint-disable-next-line regex/invalid */ }
        <Typography className={classes.phonePreview} variant="h5">
          <FormattedMessage
            id="sessions.access_code_last_four"
            defaultMessage="***-***-{lastFour}"
            values={{ lastFour }}
          />
        </Typography>
      </Box>
      <Box className={classes.formWrapper}>
        <BasicForm
          mutate={{
            mutation: signInUserByAccessCodeMutation,
            onCompleted: (res) => {
              getApolloClient().then(client => client.writeQuery({
                data: {
                  currentSession: res.signInUserByAccessCode,
                  popmenuConfig,
                },
                query: currentSessionQuery,
              }));
              if (res?.signInUserByAccessCode?.user) {
                setUseValidationAccessCode(false);
              }
            },
            toVariables: variables => ({
              accessCode: variables.accessCode,
              phone: formatPhone(newUserPhone),
            }),
          }}
        >
          {({ values }) => (
            <React.Fragment>
              <TextFieldGroup
                data-testid="access-code-validation-input"
                field="accessCode"
                autoComplete="one-time-code"
                title={<FormattedMessage id="sessions.access_code" defaultMessage="Access Code" />}
              />
              <Box align="center">
                <Button
                  data-testid="access-code-send-again"
                  className={classes.sendCodeButton}
                  variant="text"
                  textTransform="capitalize"
                  onClick={() => {
                    onReSendAccessCode();
                  }}
                >
                  <FormattedMessage id="sessions.access_code_resend_button" defaultMessage="Send code again" />
                </Button>
              </Box>
              <SubmitGroup
                block
                ButtonProps={{ lowercase: 'true' }}
                className={classes.accessCodeSubmitButton}
                color="primary"
                disabled={!values.accessCode}
                icon={false}
                justify="center"
                size="lg"
                title={<FormattedMessage id="sessions.sign_in" defaultMessage="Sign In" />}
              />
              <Box align="center">
                <Button
                  data-testid="access-code-try-a-different-number"
                  className={classes.tryDifferentButton}
                  variant="text"
                  textTransform="capitalize"
                  onClick={() => setUseDifferentNumber(true)}
                >
                  <FormattedMessage id="sessions.access_code_try_different_button" defaultMessage="Try a different number" />
                </Button>
              </Box>
            </React.Fragment>
          )}
        </BasicForm>
      </Box>
    </React.Fragment>
  );
};

export default AccessCodeValidationForm;
