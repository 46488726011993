import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Box, Icon } from '@popmenu/common-ui';
import { makeStyles } from '@material-ui/core';
import { useMutation } from '~/lazy_apollo/client';
import { getApolloClient } from '~/lazy_apollo';

import sendUserSmsAccessCodeMutation from '../../../libs/gql/mutations/users/sendUserSmsAccessCodeMutation.gql';
import destroySessionMutation from '../../../libs/gql/mutations/users/destroySessionMutation.gql';
import currentSessionQuery from '../../../libs/gql/queries/users/currentSessionQuery.gql';

import styles from './styles';
import AccessCodeSVG from '../../../assets/svg/access_code.svg';
import BasicForm, { SubmitGroup, TextFieldGroup } from '../../../admin/shared/forms/BasicForm';
import { phoneValidator } from '../../../utils/forms';
import { formatPhone } from '../../../utils/strings';
import { useIntl } from '../../../utils/withIntl';
import { usePopmenuConfig } from '../../../utils/withPopmenuConfig';
import { useSnackbar } from '../../../utils/withSnackbar';

const useStyles = makeStyles(styles);

const OneLastStep = ({ setNewUserPhone, setUseDifferentNumber, restaurantId, userEmail, isLoggedIn, userData }) => {
  const classes = useStyles();
  const t = useIntl();
  const popmenuConfig = usePopmenuConfig();
  const { showSnackbarError } = useSnackbar();
  const [destroySession] = useMutation(destroySessionMutation);

  const [sendUserSmsAccessCode] = useMutation(sendUserSmsAccessCodeMutation);

  useEffect(() => {
    if (isLoggedIn) {
      destroySession().then(({ data }) => {
        getApolloClient().then(client => client.writeQuery({
          data: {
            currentSession: data.destroySession,
            popmenuConfig,
          },
          query: currentSessionQuery,
        }));
      }).catch((err) => {
        showSnackbarError(err);
      });
    }
  }, [destroySession, isLoggedIn, popmenuConfig, showSnackbarError]);

  return (
    <React.Fragment>
      <Box align="center">
        <Icon icon={AccessCodeSVG} className={classes.accessCodeIcon} />
      </Box>
      <Box align="center">
        <Typography className={classes.header} id="become-vip-title" aria-live="polite" role="status">
          <FormattedMessage id="sessions.one_last_step_header" defaultMessage="One Last Step!" />
        </Typography>
      </Box>
      <Box align="center">
        <Typography className={classes.subheader} id="become-vip-description" aria-live="polite" role="status">
          <FormattedMessage id="sessions.one_last_step_subheader" defaultMessage="What's the best phone number to text to validate your account?" />
        </Typography>
      </Box>
      <Box className={classes.formWrapper}>
        <BasicForm
          onSubmit={(variables) => {
            setNewUserPhone(variables.phone);
            setUseDifferentNumber(false);
            sendUserSmsAccessCode({
              variables: {
                phone: variables.phone,
                restaurantId,
                userEmail: userEmail || userData.email,
              },
            });
          }}
        >
          {({ values, setValues }) => {
            if (values.phone) {
              setValues({ ...values, phone: formatPhone(values.phone) });
            }
            return (
              <React.Fragment>
                <TextFieldGroup
                  data-testid="one-last-step-phone-input"
                  field="phone"
                  type="phone"
                  validate={phoneValidator}
                  helperText={t('models.user.phone_helper_text')}
                  title={<FormattedMessage id="sessions.phone" defaultMessage="Phone Number" />}
                />
                <SubmitGroup
                  block
                  ButtonProps={{ lowercase: true }}
                  className={classes.accessCodeSubmitButton}
                  color="primary"
                  disabled={!values.phone}
                  icon={false}
                  justify="center"
                  size="lg"
                  title={<FormattedMessage id="sessions.send_code" defaultMessage="Send Code" />}
                />
              </React.Fragment>
            );
          }}
        </BasicForm>
      </Box>
    </React.Fragment>
  );
};

export default OneLastStep;
