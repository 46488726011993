import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Button } from '@popmenu/admin-ui';
import { Typography, Box, Icon } from '@popmenu/common-ui';
import { useHistory } from 'react-router-dom';

import { CheckCircle } from '@popmenu/web-icons';
import { createEvent } from '~/utils/eventable';
import { withStyles, classNames } from '../../utils/withStyles';
import signInStyles from './styles';
import { withCurrentSession } from '../CurrentSessionProvider';
import updateUserMutation from '../../libs/gql/mutations/users/updateUserMutation.gql';
import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import { AH } from '../../consumer/shared/AccessibleHeading';

const SignInVerifiedPage = ({ classes, closeModal, currentSession, goToFavoriteLocationModal, goToProfileModal, restaurant, usedAccessCode, usedMagicLink, userOffersCount }) => {
  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);
  const history = useHistory();
  const { locationsCount } = restaurant;
  const follower = currentSession?.user?.followers?.find(f => f.restaurantId === restaurant.id);
  const showFavoriteLocationModal = !isOnOloPage && currentSession.user && !follower?.favoriteLocation?.id && locationsCount > 0;

  useEffect(() => {
    createEvent({
      eventableType: 'BecomeVipV2',
      eventType: usedAccessCode ? 'v2_verify_access_code_button' : usedMagicLink ? 'magic_link_verified_page_opened' : 'v2_verify_email_button',
    });
    if (isOnOloPage && userOffersCount > 0) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | follower | offer | verified_modal',
        eventType: 'follower_profile_sign_in_verified_offer_modal',
      });
    } else if (isOnOloPage) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | follower | no_offer | verified_modal',
        eventType: 'follower_profile_sign_in_verified_no_offer_modal',
      });
    } else {
      createEvent({
        eventableType: 'BecomeVip',
        eventLabel: 'v2 | sign_in_success_modal',
        eventType: 'sign_in_success_modal',
      });
    }

    if (showFavoriteLocationModal) {
      goToFavoriteLocationModal();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.verifiedPageContainer}>
      <Icon icon={CheckCircle} color="success" className={classes.verifiedPageIcon} />
      <AH typography className={classes.verifiedPageTitle} variant="h4" id="become-vip-title" aria-live="polite" role="status">
        <FormattedMessage id="sessions.sign_in_v2_verified_title" defaultMessage="Verified!" />
      </AH>
      <Typography className={classes.verifiedPageSubtitle} id="become-vip-description" aria-live="polite" role="status">
        <FormattedMessage id="sessions.sign_in_v2_verified_subtitle" defaultMessage={`You're logged into ${restaurant.name}`} />
      </Typography>
      <Button
        color="primary"
        className={classes.verifiedPageButton}
        onClick={() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'verified_page_view_my_profile_button',
          });
          history.push('/');
          if (isOnOloPage && userOffersCount > 0) {
            createEvent({
              eventableType: 'GuestProfile',
              eventLabel: 'offer_check | follower | offer | view_offers_button | view_offers',
              eventType: 'follower_profile_sign_in_view_offers_click',
            });
            goToProfileModal('offers');
          } else {
            if (isOnOloPage) {
              createEvent({
                eventableType: 'GuestProfile',
                eventLabel: 'offer_check | follower | no_offer | explore_profile_button | explore_profile',
                eventType: 'follower_profile_sign_in_explore_click',
              });
            } else {
              createEvent({
                eventableType: 'BecomeVip',
                eventLabel: 'v2 | sign_in_success_modal | explore_profile',
                eventType: 'close_sign_in_success_click',
              });
            }
            goToProfileModal('my_profile');
          }
        }}
        size="medium"
      >
        <FormattedMessage
          id="profile.profile_v2_view_profile_button"
          defaultMessage="{cta}"
          values={{ cta: isOnOloPage && userOffersCount > 0 ? 'View Offers' : 'Explore Profile' }}
        />
      </Button>
      {isOnOloPage && (
        <Button
          className={classes.verifiedPageButton}
          color="primary"
          onClick={() => {
            createEvent({
              eventableType: 'BecomeVipV2',
              eventType: 'sign_in_success_page_back_to_order_button',
            });
            closeModal();
          }}
          variant="outlined"
          size="medium"
        >
          <FormattedMessage
            id="sessions.sign_in_v2_verified_button"
            defaultMessage="{cta}"
            values={{ cta: isOnOloPage ? 'Back to Order' : 'Done' }}
          />
        </Button>
      )}
      {(currentSession.user && !currentSession.user.lastFourDigitsPhoneNumber) && (
        <div className={classes.bottomContainer}>
          <Box className={classes.formWrapper}>
            <BasicForm
              mutate={{
                mutation: updateUserMutation,
                toVariables: variables => ({
                  userId: currentSession.user.id,
                  userInput: {
                    phone: variables.phone,
                  },
                }),
              }}
            >
              {({ values }) => (
                <React.Fragment>
                  <Typography className={classes.formTitle}>
                    <FormattedMessage id="sessions.magic_modal_subtitle" defaultMessage={'Verify your account faster next time!'} />
                  </Typography>
                  <Typography className={classNames(classes.formTitle, classes.formMessage)}>
                    <FormattedMessage id="sessions.magic_modal_subtitle_message" defaultMessage={'(also receive offers, discounts, loyalty rewards and more, directly to your phone)'} />
                  </Typography>
                  <TextFieldGroup
                    field="phone"
                    title={<FormattedMessage id="sessions.phone_number" defaultMessage="Enter Phone Number" />}
                    type="text"
                  />
                  <SubmitGroup
                    block
                    ButtonProps={{ lowercase: true }}
                    className={classes.phoneSubmitButton}
                    color="primary"
                    disabled={!values.phone}
                    icon={false}
                    justify="center"
                    size="lg"
                    title={<FormattedMessage id="sessions.save" defaultMessage="Save" />}
                    onClick={() => {
                      createEvent({
                        eventableType: 'BecomeVipV2',
                        eventType: 'v2_verified_phone_save',
                      });
                    }}
                  />
                </React.Fragment>
              )}
            </BasicForm>
          </Box>
          <Box>
            <Button
              color="primary"
              className={classes.verifiedSkipForNowButton}
              onClick={() => {
                createEvent({
                  eventableType: 'BecomeVipV2',
                  eventType: 'v2_verified_skip_for_now',
                });
                closeModal();
                history.push('/');
              }}
              size="medium"
              variant="text"
            >
              <FormattedMessage id="sessions.skip_for_now" defaultMessage="Skip for now" />
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};

SignInVerifiedPage.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentSession: PropTypes.object.isRequired,
  goToFavoriteLocationModal: PropTypes.func.isRequired,
  goToProfileModal: PropTypes.func.isRequired,
  restaurant: PropTypes.object.isRequired,
  usedAccessCode: PropTypes.bool.isRequired,
  usedMagicLink: PropTypes.bool.isRequired,
};

export default compose(
  withCurrentSession,
  withStyles(signInStyles),
)(SignInVerifiedPage);
